@import './Styles/Colors.scss';

.App{
  display: flex;
  height: 100vh;
  flex-direction: column;  
}

.header{
 text-align: center;
 background-color: $secondary;
 height: 100px;
}

.footer{
  text-align: center;
  background-color: $accent-secondary;
  height: 100px;
}

.main{
  flex: 1;
  background-color: #222;
  box-shadow: inset 0 10px 7px -7px #eee, inset 0 -10px 7px -7px #eee;
  color: #fff;
}

.row {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keybox{
    margin: 10px;
    width: 50px;
    height: 50px;
    padding: 20px;
    background-color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;
    transition: all 0.5s;

    &_label{
        text-align: center;
        font-weight: bold;
        font-size: 2em;
        color: #ddd;
    }
}

.keybox--isPressed {
  transition: all 0s;
  transform: scale(1.1);
  border-color: #eee;
  box-shadow: 0 0 5px $accent-primary;
}